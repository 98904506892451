.no-data-row {
  border-left: solid 4px #44a648;
  height: 64px; }
  .no-data-row .no-data-row-message {
    margin-left: 18px; }
  .no-data-row .no-data-row-icon {
    margin-left: 28px;
    height: 36px;
    width: 36px;
    border: 2px solid #44a648;
    border-radius: 50%;
    background-image: url(/static/media/bb8-success.608b4967.svg);
    background-position: center;
    background-size: 24px;
    background-repeat: no-repeat; }

.user-profile {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-align-items: center;
          align-items: center; }

.user {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-align-items: center;
          align-items: center;
  padding-right: 15px;
  background: right 0%/1px 100% no-repeat linear-gradient(#979797, #979797); }

.user > .avatar {
  height: 20px;
  width: 20px;
  border: 1px solid #979797;
  border-radius: 50%;
  margin-right: 10px; }

.actions {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-align-items: center;
          align-items: center; }

.bb8-badge {
  border-radius: 100px;
  color: #231F20;
  font-size: 14px;
  font-weight: 500;
  line-height: 2;
  opacity: 0.9;
  width: 108px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center; }

.bb8-blue {
  background-color: #D1E9F5; }

.bb8-orange {
  background-color: #F2C7A8; }

.bb8-pink {
  background-color: #F6CFE8; }

.bb8-green {
  background-color: #E5EFAC; }

.bb8-basic-flex {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }

.bb8-breadcrumbs-wrapper {
  margin: 0 auto;
  padding: 0;
  height: 44px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
  -webkit-align-items: center;
          align-items: center;
  list-style-type: none; }
  .bb8-breadcrumbs-wrapper > li a {
    color: #0a6fb3;
    text-decoration: none;
    font-weight: 500; }
  .bb8-breadcrumbs-wrapper > li:not(:first-of-type) {
    position: relative;
    margin-left: 24px; }
    .bb8-breadcrumbs-wrapper > li:not(:first-of-type):before {
      content: "";
      display: block;
      position: absolute;
      left: -24px;
      width: 24px;
      height: 24px;
      background-image: url(/static/media/bb8-expand_more-24px.78fbcb86.svg);
      background-position: center;
      background-size: 24px;
      background-repeat: no-repeat;
      -webkit-transform: rotate(-90deg);
              transform: rotate(-90deg);
      -webkit-transform-origin: center;
              transform-origin: center; }

.main-page {
  width: 100%;
  margin: 0 auto; }
  .main-page.padded {
    width: 1366px; }
    @media screen and (max-width: 1365px) {
      .main-page.padded {
        width: 96vw; } }

@font-face {
  font-family: 'Omnes';
  font-weight: normal;
  src: local("Omnes"), url(/static/media/omnes-regular-webfont.bf6eaba9.woff2) format("woff2"), url(/static/media/omnes-regular-webfont.99d79548.ttf) format("truetype"); }

@font-face {
  font-family: 'Omnes';
  font-style: normal;
  font-weight: 300;
  src: local("Omnes"), url(/static/media/omneslight-webfont.5fbca3f8.woff2) format("woff2"), url(/static/media/omneslight-webfont.e186e9dc.ttf) format("truetype"); }

@font-face {
  font-family: 'Omnes';
  font-style: normal;
  font-weight: 500;
  src: local("Omnes"), url(/static/media/omnesmedium-webfont.84de2660.woff2) format("woff2"), url(/static/media/omnesmedium-webfont.dc13a34f.ttf) format("truetype"); }

html,
body {
  font-family: 'Omnes', sans-serif;
  font-size: 16px; }

.BB8Carousel {
  width: 100%;
  margin-left: 0;
  padding-right: 1em;
  padding-left: 1em; }
  .BB8Carousel > button::before {
    color: gray; }
  .BB8Carousel .slick-prev {
    left: 0; }
    .BB8Carousel .slick-prev img {
      -webkit-transform: rotateY(180deg);
              transform: rotateY(180deg); }
  .BB8Carousel .slick-next {
    right: -8px; }
  .BB8Carousel .slick-prev,
  .BB8Carousel .slick-next {
    display: "block";
    height: "48px";
    width: "14px"; }
    .BB8Carousel .slick-prev:before,
    .BB8Carousel .slick-next:before {
      content: none; }

.carousel-container h3 {
  margin-bottom: 0 !important; }

.BB8Carousel-item .card-inner-wrapper {
  background: white;
  border: 2px solid #2684fb;
  border-radius: 8px !important; }

.BB8Carousel * {
  cursor: pointer; }
  .BB8Carousel * :focus {
    outline: none; }

.BB8Carousel .BB8Carousel-item {
  position: relative; }
  .BB8Carousel .BB8Carousel-item .card-inner-wrapper {
    border-color: transparent;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    padding: 0.5em;
    margin: 0.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    min-height: 12em;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .BB8Carousel .BB8Carousel-item .card-inner-wrapper > h5 {
      margin: 0;
      color: black;
      font-size: 24px;
      margin-top: 15px; }
    .BB8Carousel .BB8Carousel-item .card-inner-wrapper > p {
      margin-bottom: 15px; }
    .BB8Carousel .BB8Carousel-item .card-inner-wrapper .description {
      -webkit-flex-grow: 1;
              flex-grow: 1; }
      .BB8Carousel .BB8Carousel-item .card-inner-wrapper .description p {
        font-size: 19px;
        line-height: 26px;
        margin: 0; }
  .BB8Carousel .BB8Carousel-item.selected-carousel-item .card-inner-wrapper {
    background-color: #ebf3f8;
    border: 4px solid #61c1ee; }
  .BB8Carousel .BB8Carousel-item.selected-carousel-item:before {
    content: "";
    background-color: inherit;
    background-color: #ebf3f8;
    border-right: 4px solid #61c1ee;
    border-bottom: 4px solid #61c1ee;
    height: 19px;
    width: 19px;
    position: absolute;
    bottom: -8px;
    left: calc(50% - 9.5px);
    -webkit-transform-origin: center;
            transform-origin: center;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .BB8Carousel .BB8Carousel-item.subItems-wrapper {
    display: -webkit-flex;
    display: flex; }
    .BB8Carousel .BB8Carousel-item.subItems-wrapper .subItem {
      margin-right: 0.5em;
      border: 1px solid #999;
      padding: 3px;
      border-radius: 7px; }

.BB8Carousel .edit-icon {
  position: absolute;
  right: 20px;
  cursor: pointer;
  z-index: 100;
  height: 26px;
  min-height: 26px;
  padding: 0;
  border-radius: 4px; }

.PresetDateRangePicker_panel {
  padding: 0 22px 11px
}
.PresetDateRangePicker_button {
  position: relative;
  height: 100%;
  text-align: center;
  background: 0 0;
  border: 2px solid #00a699;
  color: #00a699;
  padding: 4px 12px;
  margin-right: 8px;
  font: inherit;
  font-weight: 700;
  line-height: normal;
  overflow: visible;
  box-sizing: border-box;
  cursor: pointer
}
.PresetDateRangePicker_button:active {
  outline: 0
}
.PresetDateRangePicker_button__selected {
  color: #fff;
  background: #00a699
}
.SingleDatePickerInput {
  display: inline-block;
  background-color: #fff
}
.SingleDatePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #dbdbdb
}
.SingleDatePickerInput__rtl {
  direction: rtl
}
.SingleDatePickerInput__disabled {
  background-color: #f2f2f2
}
.SingleDatePickerInput__block {
  display: block
}
.SingleDatePickerInput__showClearDate {
  padding-right: 30px
}
.SingleDatePickerInput_clearDate {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}
.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
  background: #dbdbdb;
  border-radius: 50%
}
.SingleDatePickerInput_clearDate__small {
  padding: 6px
}
.SingleDatePickerInput_clearDate__hide {
  visibility: hidden
}
.SingleDatePickerInput_clearDate_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle
}
.SingleDatePickerInput_clearDate_svg__small {
  height: 9px
}
.SingleDatePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px
}
.SingleDatePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle
}
.SingleDatePicker {
  position: relative;
  display: inline-block
}
.SingleDatePicker__block {
  display: block
}
.SingleDatePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute
}
.SingleDatePicker_picker__rtl {
  direction: rtl
}
.SingleDatePicker_picker__directionLeft {
  left: 0
}
.SingleDatePicker_picker__directionRight {
  right: 0
}
.SingleDatePicker_picker__portal {
  background-color: rgba(0,0,0,.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}
.SingleDatePicker_picker__fullScreenPortal {
  background-color: #fff
}
.SingleDatePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2
}
.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
  color: darken(#cacccd,10%);
  text-decoration: none
}
.SingleDatePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}
.DayPickerKeyboardShortcuts_buttonReset {
  background: 0 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  font-size: 14px
}
.DayPickerKeyboardShortcuts_buttonReset:active {
  outline: 0
}
.DayPickerKeyboardShortcuts_show {
  width: 33px;
  height: 26px;
  position: absolute;
  z-index: 2
}
.DayPickerKeyboardShortcuts_show::before {
  content: "";
  display: block;
  position: absolute
}
.DayPickerKeyboardShortcuts_show__bottomRight {
  bottom: 0;
  right: 0
}
.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-top: 26px solid transparent;
  border-right: 33px solid #00a699;
  bottom: 0;
  right: 0
}
.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right: 33px solid #008489
}
.DayPickerKeyboardShortcuts_show__topRight {
  top: 0;
  right: 0
}
.DayPickerKeyboardShortcuts_show__topRight::before {
  border-bottom: 26px solid transparent;
  border-right: 33px solid #00a699;
  top: 0;
  right: 0
}
.DayPickerKeyboardShortcuts_show__topRight:hover::before {
  border-right: 33px solid #008489
}
.DayPickerKeyboardShortcuts_show__topLeft {
  top: 0;
  left: 0
}
.DayPickerKeyboardShortcuts_show__topLeft::before {
  border-bottom: 26px solid transparent;
  border-left: 33px solid #00a699;
  top: 0;
  left: 0
}
.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
  border-left: 33px solid #008489
}
.DayPickerKeyboardShortcuts_showSpan {
  color: #fff;
  position: absolute
}
.DayPickerKeyboardShortcuts_showSpan__bottomRight {
  bottom: 0;
  right: 5px
}
.DayPickerKeyboardShortcuts_showSpan__topRight {
  top: 1px;
  right: 5px
}
.DayPickerKeyboardShortcuts_showSpan__topLeft {
  top: 1px;
  left: 5px
}
.DayPickerKeyboardShortcuts_panel {
  overflow: auto;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding: 22px;
  margin: 33px;
  text-align: left
}
.DayPickerKeyboardShortcuts_title {
  font-size: 16px;
  font-weight: 700;
  margin: 0
}
.DayPickerKeyboardShortcuts_list {
  list-style: none;
  padding: 0;
  font-size: 14px
}
.DayPickerKeyboardShortcuts_close {
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 2
}
.DayPickerKeyboardShortcuts_close:active {
  outline: 0
}
.DayPickerKeyboardShortcuts_closeSvg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}
.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: #82888a
}
.CalendarDay {
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: center
}
.CalendarDay:active {
  outline: 0
}
.CalendarDay__defaultCursor {
  cursor: default
}
.CalendarDay__default {
  border: 1px solid #e4e7e7;
  color: #484848;
  background: #fff
}
.CalendarDay__default:hover {
  background: #e4e7e7;
  border: 1px solid #e4e7e7;
  color: inherit
}
.CalendarDay__hovered_offset {
  background: #f4f5f5;
  border: 1px double #e4e7e7;
  color: inherit
}
.CalendarDay__outside {
  border: 0;
  background: #fff;
  color: #484848
}
.CalendarDay__outside:hover {
  border: 0
}
.CalendarDay__blocked_minimum_nights {
  background: #fff;
  border: 1px solid #eceeee;
  color: #cacccd
}
.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
  background: #fff;
  color: #cacccd
}
.CalendarDay__highlighted_calendar {
  background: #ffe8bc;
  color: #484848
}
.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #ffce71;
  color: #484848
}
.CalendarDay__selected_span {
  background: #66e2da;
  border: 1px double #33dacd;
  color: #fff
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #33dacd;
  border: 1px double #33dacd;
  color: #fff
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #00a699;
  border: 1px double #00a699;
  color: #fff
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #b2f1ec;
  border: 1px double #80e8e0;
  color: #007a87
}
.CalendarDay__hovered_span:active {
  background: #80e8e0;
  border: 1px double #80e8e0;
  color: #007a87
}
.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #cacccd;
  border: 1px solid #cacccd;
  color: #82888a
}
.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #fff;
  border: 1px solid #e4e7e7;
  color: #cacccd
}
.CalendarDay__hovered_start_first_possible_end {
  background: #eceeee;
  border: 1px double #eceeee
}
.CalendarDay__hovered_start_blocked_min_nights {
  background: #eceeee;
  border: 1px double #e4e7e7
}
.CalendarMonth {
  background: #fff;
  text-align: center;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}
.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0
}
.CalendarMonth_verticalSpacing {
  border-collapse: separate
}
.CalendarMonth_caption {
  color: #484848;
  font-size: 18px;
  text-align: center;
  padding-top: 22px;
  padding-bottom: 37px;
  caption-side: top;
  caption-side: initial
}
.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px;
  padding-bottom: 7px
}
.CalendarMonthGrid {
  background: #fff;
  text-align: left;
  z-index: 0
}
.CalendarMonthGrid__animating {
  z-index: 1
}
.CalendarMonthGrid__horizontal {
  position: absolute;
  left: 9px
}
.CalendarMonthGrid__vertical {
  margin: 0 auto
}
.CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto;
  overflow-y: scroll
}
.CalendarMonthGrid_month__horizontal {
  display: inline-block;
  vertical-align: top;
  min-height: 100%
}
.CalendarMonthGrid_month__hideForAnimation {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none
}
.CalendarMonthGrid_month__hidden {
  visibility: hidden
}
.DayPickerNavigation {
  position: relative;
  z-index: 2
}
.DayPickerNavigation__horizontal {
  height: 0
}
.DayPickerNavigation__verticalDefault {
  position: absolute;
  width: 100%;
  height: 52px;
  bottom: 0;
  left: 0
}
.DayPickerNavigation__verticalScrollableDefault {
  position: relative
}
.DayPickerNavigation_button {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0;
  margin: 0
}
.DayPickerNavigation_button__default {
  border: 1px solid #e4e7e7;
  background-color: #fff;
  color: #757575
}
.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border: 1px solid #c4c4c4
}
.DayPickerNavigation_button__default:active {
  background: #f2f2f2
}
.DayPickerNavigation_button__disabled {
  cursor: default;
  border: 1px solid #f2f2f2
}
.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
  border: 1px solid #f2f2f2
}
.DayPickerNavigation_button__disabled:active {
  background: 0 0
}
.DayPickerNavigation_button__horizontalDefault {
  position: absolute;
  top: 18px;
  line-height: .78;
  border-radius: 3px;
  padding: 6px 9px
}
.DayPickerNavigation_leftButton__horizontalDefault {
  left: 22px
}
.DayPickerNavigation_rightButton__horizontalDefault {
  right: 22px
}
.DayPickerNavigation_button__verticalDefault {
  padding: 5px;
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
  position: relative;
  display: inline-block;
  text-align: center;
  height: 100%;
  width: 50%
}
.DayPickerNavigation_nextButton__verticalDefault {
  border-left: 0
}
.DayPickerNavigation_nextButton__verticalScrollableDefault {
  width: 100%
}
.DayPickerNavigation_svg__horizontal {
  height: 19px;
  width: 19px;
  fill: #82888a;
  display: block
}
.DayPickerNavigation_svg__vertical {
  height: 42px;
  width: 42px;
  fill: #484848
}
.DayPickerNavigation_svg__disabled {
  fill: #f2f2f2
}
.DayPicker {
  background: #fff;
  position: relative;
  text-align: left
}
.DayPicker__horizontal {
  background: #fff
}
.DayPicker__verticalScrollable {
  height: 100%
}
.DayPicker__hidden {
  visibility: hidden
}
.DayPicker__withBorder {
  box-shadow: 0 2px 6px rgba(0,0,0,.05),0 0 0 1px rgba(0,0,0,.07);
  border-radius: 3px
}
.DayPicker_portal__horizontal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%
}
.DayPicker_portal__vertical {
  position: static;
  position: initial
}
.DayPicker_focusRegion {
  outline: 0
}
.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
  display: inline-block;
  vertical-align: top
}
.DayPicker_weekHeaders {
  position: relative
}
.DayPicker_weekHeaders__horizontal {
  margin-left: 9px
}
.DayPicker_weekHeader {
  color: #757575;
  position: absolute;
  top: 62px;
  z-index: 2;
  text-align: left
}
.DayPicker_weekHeader__vertical {
  left: 50%
}
.DayPicker_weekHeader__verticalScrollable {
  top: 0;
  display: table-row;
  border-bottom: 1px solid #dbdbdb;
  background: #fff;
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center
}
.DayPicker_weekHeader_ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px
}
.DayPicker_weekHeader_li {
  display: inline-block;
  text-align: center
}
.DayPicker_transitionContainer {
  position: relative;
  overflow: hidden;
  border-radius: 3px
}
.DayPicker_transitionContainer__horizontal {
  transition: height .2s ease-in-out
}
.DayPicker_transitionContainer__vertical {
  width: 100%
}
.DayPicker_transitionContainer__verticalScrollable {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll
}
.DateInput {
  margin: 0;
  padding: 0;
  background: #fff;
  position: relative;
  display: inline-block;
  width: 130px;
  vertical-align: middle
}
.DateInput__small {
  width: 97px
}
.DateInput__block {
  width: 100%
}
.DateInput__disabled {
  background: #f2f2f2;
  color: #dbdbdb
}
.DateInput_input {
  font-weight: 200;
  font-size: 19px;
  line-height: 24px;
  color: #484848;
  background-color: #fff;
  width: 100%;
  padding: 11px 11px 9px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0
}
.DateInput_input__small {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: .2px;
  padding: 7px 7px 5px
}
.DateInput_input__regular {
  font-weight: auto
}
.DateInput_input__readOnly {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}
.DateInput_input__focused {
  outline: 0;
  background: #fff;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #008489;
  border-left: 0
}
.DateInput_input__disabled {
  background: #f2f2f2;
  font-style: italic
}
.DateInput_screenReaderMessage {
  border: 0;
  clip: rect(0,0,0,0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}
.DateInput_fang {
  position: absolute;
  width: 20px;
  height: 10px;
  left: 22px;
  z-index: 2
}
.DateInput_fangShape {
  fill: #fff
}
.DateInput_fangStroke {
  stroke: #dbdbdb;
  fill: transparent
}
.DateRangePickerInput {
  background-color: #fff;
  display: inline-block
}
.DateRangePickerInput__disabled {
  background: #f2f2f2
}
.DateRangePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #dbdbdb
}
.DateRangePickerInput__rtl {
  direction: rtl
}
.DateRangePickerInput__block {
  display: block
}
.DateRangePickerInput__showClearDates {
  padding-right: 30px
}
.DateRangePickerInput_arrow {
  display: inline-block;
  vertical-align: middle;
  color: #484848
}
.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #484848;
  height: 24px;
  width: 24px
}
.DateRangePickerInput_clearDates {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%)
}
.DateRangePickerInput_clearDates__small {
  padding: 6px
}
.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: #dbdbdb;
  border-radius: 50%
}
.DateRangePickerInput_clearDates__hide {
  visibility: hidden
}
.DateRangePickerInput_clearDates_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle
}
.DateRangePickerInput_clearDates_svg__small {
  height: 9px
}
.DateRangePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px
}
.DateRangePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle
}
.DateRangePicker {
  position: relative;
  display: inline-block
}
.DateRangePicker__block {
  display: block
}
.DateRangePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute
}
.DateRangePicker_picker__rtl {
  direction: rtl
}
.DateRangePicker_picker__directionLeft {
  left: 0
}
.DateRangePicker_picker__directionRight {
  right: 0
}
.DateRangePicker_picker__portal {
  background-color: rgba(0,0,0,.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}
.DateRangePicker_picker__fullScreenPortal {
  background-color: #fff
}
.DateRangePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2
}
.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
  color: darken(#cacccd,10%);
  text-decoration: none
}
.DateRangePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}
.date-range-picker-container {
  display: inline-block;
  position: relative; }
  .date-range-picker-container .date-range-picker-wrapper {
    position: absolute;
    right: 0;
    margin-top: .5em;
    z-index: 1000;
    background: #fff;
    border: 1px solid #979797;
    box-shadow: 1px 4px 2px 0 #979797;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    width: 622px; }
    .date-range-picker-container .date-range-picker-wrapper .date-range-picker-inputs {
      width: 100%;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      padding: 1em; }
      .date-range-picker-container .date-range-picker-wrapper .date-range-picker-inputs span {
        color: #d5dbdf;
        -webkit-flex-grow: 0;
                flex-grow: 0;
        height: 2.5em;
        border-radius: 3px;
        border: 1px solid #d5dbdf;
        margin: 0 .5em;
        width: 6.5em;
        padding: 0.5em; }
    .date-range-picker-container .date-range-picker-wrapper .date-range-picker-content {
      min-height: 330px; }
      .date-range-picker-container .date-range-picker-wrapper .date-range-picker-content .DayPicker__hidden {
        visibility: visible; }
      .date-range-picker-container .date-range-picker-wrapper .date-range-picker-content .CalendarDay__selected_span {
        background: #d1e9f5;
        color: black;
        border: 1px solid transparent; }
      .date-range-picker-container .date-range-picker-wrapper .date-range-picker-content .CalendarDay__selected {
        background: #0a6fb3;
        color: #fff; }
      .date-range-picker-container .date-range-picker-wrapper .date-range-picker-content .CalendarDay__selected:hover {
        background: #61c1ee;
        color: #fff; }
      .date-range-picker-container .date-range-picker-wrapper .date-range-picker-content .CalendarDay__hovered_span:hover,
      .date-range-picker-container .date-range-picker-wrapper .date-range-picker-content .CalendarDay__hovered_span {
        background: #1790cc; }
    .date-range-picker-container .date-range-picker-wrapper .date-range-picker-footer {
      padding: 1em;
      width: 100%;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end; }
      .date-range-picker-container .date-range-picker-wrapper .date-range-picker-footer button {
        margin-left: 1em; }

.bb8-basic-flex {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }

.bb8-search-wrapper {
  position: relative;
  min-height: 3em;
  width: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto; }
  .bb8-search-wrapper .bb8-search {
    background: #f1f2f2;
    color: rgba(94, 94, 94, 0.5);
    -webkit-flex: 1 1 100%;
            flex: 1 1 100%;
    font-family: "Omnes";
    font-size: 16px;
    border-radius: 3px;
    border: 0;
    padding-left: 1em;
    padding-right: 1em;
    height: 100%;
    width: 100%; }
    .bb8-search-wrapper .bb8-search:focus {
      outline: none;
      box-shadow: 0 0 1pt 1pt #fff; }
    .bb8-search-wrapper .bb8-search.dirty.invalid {
      background: #faa; }
    .bb8-search-wrapper .bb8-search.valid {
      background: #f1f2f2; }
  .bb8-search-wrapper .bb8-search-icon {
    position: absolute;
    right: 0rem;
    visibility: hidden;
    pointer-events: none; }
    .bb8-search-wrapper .bb8-search-icon.show {
      visibility: visible;
      pointer-events: all; }
  .bb8-search-wrapper .bb8-offer-modal-style {
    background: none !important;
    font: inherit !important;
    color: rgba(0, 0, 0, 0.54) !important;
    width: 220px !important;
    border: 1px solid gray !important;
    margin-top: 21px !important;
    padding: 27px 12px !important; }
  .bb8-search-wrapper .bb8-customer-modal-style {
    background: none !important;
    font: inherit !important;
    color: rgba(0, 0, 0, 0.54) !important;
    width: 450px !important;
    border: 1px solid gray !important;
    margin-top: 21px !important;
    padding: 27px 12px !important; }

.margin-20-top {
  margin-top: 20px !important; }

.rem-1-font {
  font-size: 1rem !important; }

.bb8-link {
  margin-bottom: -11px;
  width: 30px; }

.modal-content {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .modal-content.default {
    width: 750px; }
  .modal-content.small {
    width: 400px; }
  .modal-content.medium {
    width: 550px; }
  .modal-content .btn-close-modal {
    float: right; }

.bb8-basic-flex, .bb8-nav-bar, .bb8-nav-bar .bb8-title-wapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }

.bb8-nav-bar-wrapper {
  margin: 0 auto;
  background-image: url(data:image/jpeg;base64,/9j/4QAYRXhpZgAASUkqAAgAAAAAAAAAAAAAAP/sABFEdWNreQABAAQAAABkAAD/4QMxaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLwA8P3hwYWNrZXQgYmVnaW49Iu+7vyIgaWQ9Ilc1TTBNcENlaGlIenJlU3pOVGN6a2M5ZCI/PiA8eDp4bXBtZXRhIHhtbG5zOng9ImFkb2JlOm5zOm1ldGEvIiB4OnhtcHRrPSJBZG9iZSBYTVAgQ29yZSA1LjYtYzExMSA3OS4xNTgzMjUsIDIwMTUvMDkvMTAtMDE6MTA6MjAgICAgICAgICI+IDxyZGY6UkRGIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+IDxyZGY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCBDQyAyMDE1IChNYWNpbnRvc2gpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkEzMjVCOTEyQjQwNDExRThBRjBDRTY4ODQ5RkFDQUY1IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkEzMjVCOTEzQjQwNDExRThBRjBDRTY4ODQ5RkFDQUY1Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6OTkxM0M3N0ZCMzg2MTFFOEFGMENFNjg4NDlGQUNBRjUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6OTkxM0M3ODBCMzg2MTFFOEFGMENFNjg4NDlGQUNBRjUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz7/7gAOQWRvYmUAZMAAAAAB/9sAhAABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAgICAgICAgICAgIDAwMDAwMDAwMDAQEBAQEBAQIBAQICAgECAgMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwP/wAARCABAAAEDAREAAhEBAxEB/8QAaAABAAMAAAAAAAAAAAAAAAAAAAIEBwEBAQEAAAAAAAAAAAAAAAAAAAUJEAEAAAILAAAAAAAAAAAAAAAAAQXRAlKSAxPTBJQVVREBAAAFBAMAAAAAAAAAAAAAANECklQFAwQVFgFTBv/aAAwDAQACEQMRAD8AybtZp6Uw5u41GvPA4q129EsGC3Z/ob3c1zRUFlBAAAAATy8SxXuxoSecxPv06vEVvreetNeiaD//2Q==);
  background-size: contain;
  height: 78px;
  box-shadow: 0px 3px 2px 0px #dddddd;
  margin-bottom: 4px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }
  .bb8-nav-bar-wrapper h1 {
    color: #ffffff;
    font-size: 40px; }

.bb8-nav-bar {
  font-family: "Omnes", Calibri, "Trebuchet MS";
  width: 1366px; }
  @media screen and (max-width: 1365px) {
    .bb8-nav-bar {
      width: 100%; } }
  .bb8-nav-bar * {
    -webkit-flex-grow: 1;
            flex-grow: 1; }
  .bb8-nav-bar .bb8-title-img-wrapper {
    -webkit-flex-grow: 0;
            flex-grow: 0; }
    .bb8-nav-bar .bb8-title-img-wrapper img {
      height: auto;
      width: 48px; }
  .bb8-nav-bar .bb8-title-wapper * {
    margin-right: 1em; }
  .bb8-nav-bar .bb8-title-wapper h1 {
    font-weight: lighter; }

#spinner-root.shown {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9000; }

.bb8-step {
  border-radius: 20px;
  margin-top: -5px;
  padding: 4px;
  padding-bottom: 0px; }
  .bb8-step.rejected {
    border: 2px solid red; }
  .bb8-step.hold {
    border: 2px solid orange; }
  .bb8-step.processing {
    border: 2px solid #2e6fae; }
  .bb8-step.completed {
    border: 2px solid green; }
  .bb8-step.idle {
    border: 2px solid gray;
    padding: 14px; }

.bb8-stepper {
  font-family: Omnes; }
  .bb8-stepper.title {
    line-height: 16px;
    font-weight: bold; }
  .bb8-stepper.last-update {
    color: #9B9B9B;
    font-family: Arial;
    font-size: 10px;
    font-style: italic;
    line-height: 11px; }

.bb8-basic-flex {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }

.bb8-search-wrapper {
  position: relative;
  height: 3em;
  width: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto; }
  .bb8-search-wrapper .bb8-search {
    background: #f1f2f2;
    color: rgba(94, 94, 94, 0.5);
    -webkit-flex: 1 1 100%;
            flex: 1 1 100%;
    font-family: "Omnes";
    font-size: 16px;
    border-radius: 3px;
    border: 0;
    padding-left: 1em;
    padding-right: 1em;
    height: 100%;
    width: 100%; }
    .bb8-search-wrapper .bb8-search:focus {
      outline: none;
      box-shadow: 0 0 1pt 1pt #fff; }
    .bb8-search-wrapper .bb8-search.dirty.invalid {
      background: #faa; }
    .bb8-search-wrapper .bb8-search.valid {
      background: #f1f2f2; }
  .bb8-search-wrapper .bb8-search-icon {
    position: absolute;
    right: 0rem; }

.bb8-basic-flex {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }

.navbar-links-wrapper {
  width: 100%;
  height: 45px;
  background-color: #ffffff; }
  .navbar-links-wrapper .navbar-links {
    width: 1366px;
    height: 100%;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-align-items: center;
            align-items: center;
    margin: 0 auto; }
    @media screen and (max-width: 1365px) {
      .navbar-links-wrapper .navbar-links {
        width: 100%; } }
    .navbar-links-wrapper .navbar-links > nav {
      display: inherit;
      height: 100%; }
      .navbar-links-wrapper .navbar-links > nav > a {
        display: block;
        height: 100%;
        line-height: 45px;
        color: #7c7e80;
        font-family: Omnes;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.25px;
        text-decoration: none;
        text-transform: uppercase;
        padding-left: 10px;
        padding-right: 10px; }
        .navbar-links-wrapper .navbar-links > nav > a:first-child {
          margin-right: 44px; }
        .navbar-links-wrapper .navbar-links > nav > a.active {
          color: #0a6fb3;
          background: top left/100% 4px no-repeat linear-gradient(#0a6fb3, #0a6fb3); }
    .navbar-links-wrapper .navbar-links > .user-profile {
      position: absolute;
      right: 0; }
  .navbar-links-wrapper .navbar-sub-links {
    width: 1366px;
    height: 100%;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-align-items: center;
            align-items: center;
    margin: 0 auto;
    background-color: #3a84b2; }
    @media screen and (max-width: 1365px) {
      .navbar-links-wrapper .navbar-sub-links {
        width: 100%; } }
    .navbar-links-wrapper .navbar-sub-links > nav {
      display: inherit;
      height: 100%; }
      .navbar-links-wrapper .navbar-sub-links > nav > a {
        display: block;
        height: 100%;
        line-height: 45px;
        font-family: Omnes;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.25px;
        text-decoration: none;
        text-transform: uppercase;
        color: white;
        padding-left: 10px;
        padding-right: 10px; }
        .navbar-links-wrapper .navbar-sub-links > nav > a:first-child {
          margin-right: 44px; }
        .navbar-links-wrapper .navbar-sub-links > nav > a.active {
          background-color: #317099; }
  .navbar-links-wrapper .btn-login {
    margin-left: auto; }

.sub-header {
  background-color: #3a84b2; }

.bb8-basic-flex {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center; }

main.page {
  -webkit-align-items: center;
          align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
  height: calc(100% - 180px);
  -webkit-justify-content: center;
          justify-content: center;
  width: 100%; }
  main.page > * {
    color: #fff; }
  main.page h1 {
    font-size: 150px;
    margin-bottom: 2rem; }
  main.page h2 {
    font-size: 64px; }
  main.page .link {
    background-color: #0a6fb3;
    padding: 1rem 3rem;
    text-decoration: none; }

